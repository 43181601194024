/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const Bio = ({ author }) => {
  return (
    <div
      style={{
        display: `flex`,
        marginTop: rhythm(1),
        marginBottom: rhythm(1),
      }}
    >
      <Image
        fixed={author.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          width: '4rem',
          height: '4rem',
          borderRadius: `100%`,
          flexShrink: 0,
        }}
        imgStyle={{
          borderRadius: `50%`,
          filter: 'none',
        }}
      />
      <p>
        <strong>{author.name}&nbsp;</strong> {author.bio}
      </p>
    </div>
  );
};

export default Bio;
