import React, { useState, useLayoutEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import { rhythm } from '../utils/typography';
import { useActiveSections } from '../utils/hooks';

const kWindowTop = 20; // Offset from top of window to begin counting of active sections.
const kIndentWidth = 14; // Space left of headings.
const kHeadingPadding = 2; // Space above and below headings.

const TableOfContentsWrapper = styled.div({
  position: 'relative', // So `Indicator` svg is anchored to top.
  marginTop: rhythm(1 / 4),
  marginBottom: rhythm(1 / 4),
});

const List = styled.ol({
  marginTop: 0,
  marginBottom: 0,
  marginLeft: kIndentWidth,
  listStyle: 'none',
  counterReset: 'toc',
});

const ListItem = styled.li({
  marginTop: 0,
  marginBottom: 0,
  counterIncrement: 'toc',
  '& a:before': {
    // Add content to link to synchonize highlighting.
    content: 'counters(toc, ".") "\0a0\0a0"',
  },
});

const HeadingWrapper = styled.div({
  paddingTop: kHeadingPadding, // Must be integer value (not rhythm()) for Safari-compatible subpixel sizing.
  paddingBottom: kHeadingPadding,
});

const HeadingLink = styled.a(({ theme, active }) => ({
  display: 'inline-flex',
  textDecoration: 'none',
  borderBottom: 'none',
  transition: 'all 0.2s ease',
  color: active ? theme.gray.darker : theme.gray.light,
  marginLeft: active ? 4 : 0,
  marginRight: active ? 0 : 4,
  '&:hover, &:active': {
    borderBottom: 'none',
    color: theme.gray.darker,
    marginLeft: 4,
    marginRight: 0,
  },
}));

// Starting positions of `Indicator`, to compensate for stroke width.
const kIndicatorX = 2;
const kIndicatorY = 0;

const Indicator = styled.svg(({ theme }) => ({
  position: 'absolute',
  top: 0,
  overflow: 'visible !important',
  width: '100%',
  pointerEvents: 'none',
  '& path': {
    stroke: theme.gray.base,
    fill: 'transparent',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
}));

export default function TableOfContents({ toc, depth = 2 }) {
  // Determine which heading sections are currently active.
  const headingIds = useMemo(() => {
    const ids = [];
    function gatherHeadingIds(currItems, currDepth) {
      if (!currItems) return null;
      if (currDepth > depth) return;
      currItems.forEach(({ url, title, items }) => {
        if (!url || !title) return;
        ids.push(url.slice(1)); // Remove leading '#'.
        gatherHeadingIds(items, currDepth + 1);
      });
    }
    gatherHeadingIds(toc.items, 1);
    return ids;
  }, [toc, depth]);
  const activeIds = useActiveSections(headingIds, { topPx: kWindowTop, throttleMs: 100 });

  // Recursively render toc list items. Populate `indicatorInfo` with information that is needed
  // to draw the indicator path; this will be reconstructed on every render.
  const indicatorInfo = [];
  function renderItems(currItems, currDepth) {
    if (!currItems) return null;
    if (currDepth > depth) return null;

    return (
      <List depth={currDepth}>
        {currItems.map(({ url, title, items }, idx) => {
          if (!url || !title) return null;
          const isActive = activeIds.includes(url.slice(1)); // Remove leading '#'.
          return (
            <ListItem key={idx}>
              <HeadingWrapper
                ref={(r) => {
                  if (!r) return; // Do nothing on unmount call.
                  const rect = r.getBoundingClientRect();
                  indicatorInfo.push({
                    height: rect ? rect.height : 0,
                    depth: currDepth,
                    active: isActive,
                  });
                }}
              >
                <HeadingLink href={url} active={isActive}>
                  <div>{title}</div>
                </HeadingLink>
              </HeadingWrapper>
              {renderItems(items, currDepth + 1)}
            </ListItem>
          );
        })}
      </List>
    );
  }

  // Construct the SVG path element for the indicator. Need to be within `useLayoutEffect` since
  // `indicatorInfo` is only populated after DOM mount with the element heights, and since the
  // effect should only be performed after all mounting.
  const [indicatorPath, setIndicatorPath] = useState({ path: '', dasharray: '' });
  useLayoutEffect(() => {
    const path = ['M', kIndicatorX, kIndicatorY];
    let pathLength = 0,
      pathStart = undefined,
      pathEnd = undefined;
    let lastDepth = 1;
    for (let i = 0; i < indicatorInfo.length; i++) {
      const { height, depth, active } = indicatorInfo[i];
      if (depth > lastDepth) {
        path.push('l', kIndentWidth, 0);
        pathLength += kIndentWidth;
      } else if (depth < lastDepth) {
        path.push('l', -kIndentWidth, 0);
        pathLength += kIndentWidth;
      }

      if (active && pathStart === undefined) pathStart = pathLength;
      path.push('l', 0, height);
      pathLength += height;
      if (active) pathEnd = pathLength;

      lastDepth = depth;
    }

    setIndicatorPath({
      path: path.join(' '),
      dasharray: `1 ${pathStart || 0} ${pathEnd - pathStart || 0} ${pathLength + 1}`,
    });
  }, [activeIds]); // Indicator only ever needs to change when `activeIds` changed.

  return (
    <TableOfContentsWrapper>
      {renderItems(toc.items, 1)}
      <Indicator>
        <path
          strokeDasharray={indicatorPath.dasharray}
          strokeDashoffset='1'
          d={indicatorPath.path}
          css={{ transition: 'all 0.3s ease' }}
        ></path>
      </Indicator>
    </TableOfContentsWrapper>
  );
}
