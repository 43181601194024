import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';

import { rhythm } from '../utils/typography';

const kButtonHeight = '1.5rem';
const kButtonWidth = '5rem';

const Button = styled.div(({ theme }) => ({
  backgroundColor: theme.gray.lightest,
  color: theme.gray.base,
  border: `${theme.gray.lighter} 1px solid`,
  borderRadius: 4,
  transition: 'filter 0.2s ease',
  '&:hover': {
    filter: theme.dark ? 'brightness(130%)' : 'brightness(102%)',
  },

  // User mouse should act as button.
  cursor: 'pointer',
  userSelect: 'none',

  // Horizontally center button over bottom edge of container.
  position: 'absolute',
  height: kButtonHeight,
  width: kButtonWidth,
  bottom: `calc(-${kButtonHeight} / 2)`,
  left: '50%',
  marginLeft: `calc(-${kButtonWidth} / 2)`,

  // Center text vertically and horizontally.
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BoxWrapper = styled.div(({ maxHeight, theme }) => ({
  backgroundColor: theme.gray.lightest,
  border: `${theme.gray.lighter} 1px solid`,
  borderRadius: 2,
  fontSize: '0.9rem',

  minHeight: rhythm(2),
  maxHeight: `calc(${maxHeight} - ${kButtonHeight} / 2)`,
  marginTop: rhythm(1),
  marginBottom: rhythm(1),
  padding: rhythm(0.5),
  paddingBottom: `calc(${rhythm(0.5)} + ${kButtonHeight} / 2)`,
  position: 'relative',
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentWrapper = styled.div(({ overflow }) => ({
  overflow,
  '& p:last-of-type': {
    marginBottom: 0,
  },
}));

const Title = styled.div(({ theme }) => ({
  marginBottom: rhythm(0.5),
  fontWeight: 'bold',
  color: theme.gray.darkest,
}));

const Fade = styled.div(({ opacity, theme }) => ({
  height: rhythm(3),
  position: 'absolute',
  bottom: `calc(${kButtonHeight} / 2)`,
  left: 0,
  right: 0,
  backgroundImage: `linear-gradient(to bottom, ${theme.gray.lightest.replace(')', ', 0)')}, ${
    theme.gray.lightest
  })`,
  pointerEvents: 'none',
  transition: 'opacity 0.3s ease',
  opacity,
}));

export default function Box({ children, height = 160, title }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  return (
    <BoxWrapper
      maxHeight={isExpanded ? '100vh' : typeof height === 'number' ? `${height}px` : height}
    >
      {title && <Title>{title}</Title>}
      <ContentWrapper ref={contentRef} overflow={isExpanded ? 'auto' : 'hidden'}>
        {children}
      </ContentWrapper>
      {<Fade opacity={isExpanded ? 0 : 1} />}
      <Button
        onClick={() => {
          setIsExpanded(!isExpanded);
          if (contentRef.current) contentRef.current.scroll({ top: 0, left: 0 });
        }}
      >
        {isExpanded ? <i className='fas fa-angle-up'></i> : <i className='fas fa-angle-down'></i>}
      </Button>
    </BoxWrapper>
  );
}
