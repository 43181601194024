import React, { useState, useLayoutEffect, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import cuid from 'cuid';

import { kBreakpoint1, kBreakpointNot1 } from '../components/layout';
import { rhythm } from '../utils/typography';
import { useActiveSections } from '../utils/hooks';

const SlideshowWrapper = styled.div({
  position: 'relative', // To allow absolute positioning of children.
  [kBreakpoint1]: {
    // Create space for image.
    paddingTop: '75vh',
  },
});

const SlideshowAnchor = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
});

const SlideTrigger = styled.div({
  height: 0,
});

const SlideWrapper = styled.div(({ active, theme }) => ({
  position: 'sticky',
  opacity: active ? '100%' : '0%',
  transition: 'all 0.3s ease',
  [kBreakpointNot1]: {
    top: rhythm(1),
  },
  [kBreakpoint1]: {
    top: 0,
    paddingTop: rhythm(0.5),
    paddingBottom: rhythm(0.5),
    backgroundColor: theme.background,
  },
}));

// For passing down the currently active slide ID.
const SlideshowContext = React.createContext('');

export function Slideshow({ children }) {
  const slideshowRef = useRef(null);
  const [slideIds, setSlideIds] = useState([]);
  const [activeId, setActiveId] = useState('');

  // After first layout, store `slideId` of children `Slides`.
  useLayoutEffect(() => {
    if (slideshowRef.current) {
      const triggers = slideshowRef.current.getElementsByClassName(`${SlideTrigger}`.slice(1)); // Remove leading '.'.
      setSlideIds(Array.from(triggers).map((t) => t.id));
    }
  }, [slideshowRef]);

  const activeIds = useActiveSections(slideIds, { bottomPercent: 25 });
  if (activeIds.length > 0 && activeIds[activeIds.length - 1] !== activeId) {
    setActiveId(activeIds[activeIds.length - 1]);
  }

  return (
    <SlideshowContext.Provider value={activeId}>
      <SlideshowWrapper ref={slideshowRef}>{children}</SlideshowWrapper>
    </SlideshowContext.Provider>
  );
}

export function Slide({ children }) {
  const [slideId /* not used */] = useState(cuid.slug());
  const slideRef = useRef(null);
  const activeId = useContext(SlideshowContext);

  // Calculate sum of children heights to prevent floated children from being shown past
  // the Slideshow end.
  const anchorStyles = slideRef &&
    slideRef.current && {
      [kBreakpointNot1]: {
        bottom: Array.from(slideRef.current.children).reduce(
          (sum, c) => sum + c.getBoundingClientRect().height,
          0,
        ),
      },
    };

  return (
    <React.Fragment>
      <SlideshowAnchor css={anchorStyles}>
        <SlideWrapper ref={slideRef} active={activeId === slideId}>
          {children}
        </SlideWrapper>
      </SlideshowAnchor>
      <SlideTrigger id={slideId} />
    </React.Fragment>
  );
}

// TODO: Change Slide to take role of figure. With margin or body option. May also allow fancy overlaying.
