import bibtex from 'bibtex-parse-js';

// ===============================================================================================
// String formatting functions.

function authorString(ent, template, show = 10) {
  if (!ent.author) return '';
  const names = ent.author.split(' and ');
  if (names.length === 0) return '';
  let name_strings = names.map((name) => {
    name = name.trim();

    let last, firsts;
    if (name.indexOf(',') !== -1) {
      last = name.split(',')[0].trim();
      firsts = name.split(',')[1];
    } else {
      last = name.split(' ').slice(-1)[0].trim();
      firsts = name.split(' ').slice(0, -1).join(' ');
    }
    let initials = '';
    if (firsts) {
      initials = firsts
        .trim()
        .split(' ')
        .map((s) => s.trim()[0]);
      initials = initials.join('.') + '.';
    }
    return template.replace('{F}', firsts).replace('{L}', last).replace('{I}', initials);
  });
  if (names.length > 1) {
    if (names.length > show) {
      return name_strings.slice(0, show).join(', ') + ', et al. ';
    } else {
      let str = name_strings.slice(0, names.length - 1).join(', ');
      str += (' and ' || ', ') + name_strings[names.length - 1];
      return str + '. ';
    }
  } else {
    return name_strings[0] + '. ';
  }
}

function venueString(ent) {
  let cite = '';
  if (ent.journal || ent.booktitle) {
    cite += '<i>' + (ent.journal || ent.booktitle) + '</i>';
  } else if (ent.archivePrefix && ent.eprint) {
    cite += '<i>' + ent.archivePrefix + ':' + ent.eprint + '</i>';
  }
  if (cite !== '') {
    if ('volume' in ent) {
      let issue = ent.issue || ent.number;
      issue = issue !== undefined ? '(' + issue + ')' : '';
      cite += ', vol. ' + ent.volume + issue;
    }
    if ('pages' in ent) {
      cite += ', p. ' + ent.pages.replace('--', '-');
    }
  }
  if (cite !== '') cite += ' ';
  if (ent.year || ent.date) {
    cite += '(' + (ent.year || ent.date) + ')';
  }
  if (cite !== '') cite += '.';
  return cite;
}

function urlString(ent) {
  let url = ent.url;
  if (url) {
    const arxiv_match = /arxiv\.org\/abs\/([0-9.]*)/.exec(url);
    if (arxiv_match) {
      url = `http://arxiv.org/pdf/${arxiv_match[1]}.pdf`;
    }
  } else if (ent.archivePrefix === 'arXiv' && ent.eprint) {
    url = `http://arxiv.org/pdf/${ent.eprint}.pdf`;
  } else {
    return '';
  }

  let label = 'link';
  if (url.slice(-4) === '.pdf') {
    label = 'pdf';
  } else if (url.slice(-5) === '.html') {
    label = 'html';
  }
  return `<span class="citation-link">&ensp;[<a href="${url}" target="_blank" rel="noopener noreferrer">${label}</a>]</span>`;
}

function doiString(ent, new_line) {
  if ('doi' in ent) {
    return `${new_line ? '<br/>' : ''} <a href="https://doi.org/${
      ent.doi
    }" style="text-decoration:inherit;">DOI: ${ent.doi}</a>`;
  } else {
    return '';
  }
}

function citationHtml(ent) {
  if (ent) {
    let cite = '<b>' + ent.title + '</b> ';
    cite += urlString(ent) + '<br/>';
    cite += authorString(ent, '{I} {L}', 10);
    cite += venueString(ent);
    // cite += doiString(ent);
    return cite;
  } else {
    return '?';
  }
}

function normalizeTag(string) {
  return string
    .replace(/[\t\n ]+/g, ' ')
    .replace(/{\\["^`.'acu~Hvs]( )?([a-zA-Z])}/g, (full, x, char) => char)
    .replace(/{\\([a-zA-Z])}/g, (full, char) => char);
}

export function makeCitations() {
  // ===============================================================================================
  // Build bibliography map from <bibliography> tags.

  const bibliography = new Map(); // 'foo2020' -> { title: string, author: string, ... }
  const bibnodes = Array.from(document.querySelectorAll('cite-list'));
  const bibtext = bibnodes
    .map((node) => node.innerHTML.replace('<p>', '').replace('</p>', ''))
    .join('\n');
  const entries = bibtex.toJSON(bibtext);
  for (const entry of entries) {
    for (const [key, value] of Object.entries(entry.entryTags)) {
      entry.entryTags[key.toLowerCase()] = normalizeTag(value);
    }
    entry.entryTags.type = entry.entryType;
    bibliography.set(entry.citationKey, entry.entryTags);
  }

  // ===============================================================================================
  // Replace <cite> tags with numbered inline links.

  const citations = [];
  function citationNumber(key) {
    if (bibliography.has(key)) {
      if (!citations.includes(key)) citations.push(key);
      const n = citations.indexOf(key) + 1;
      return `${n}`;
    } else {
      return '?';
    }
  }

  const citenodes = document.querySelectorAll('cite');
  citenodes.forEach((node) => {
    const keys = node.innerText.split(',').map((key) => key.trim());
    const keysHTML = keys
      .map((key) => {
        const n = citationNumber(key);
        return `<a href="#citation-${n}">${n}</a>`;
      })
      .join(',&nbsp;');
    node.innerHTML = `<div class="citation-inline"><span>[${keysHTML}]</span></div>`;
  });

  // ===============================================================================================
  // Inject entry list into last <bibliography> tag.

  bibnodes.forEach((node, i) => {
    if (i < bibnodes.length - 1) {
      if (node.parentNode) node.parentNode.removeChild(node);
      return;
    }

    const itemsHTML = citations
      .map((key, i) => `<li id="citation-${i + 1}">${citationHtml(bibliography.get(key))}</li>`)
      .join('');
    node.innerHTML = `<ol>${itemsHTML}</ol>`;
  });
}
